import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { useAuth } from '../AuthProvider.tsx';
import MenuListComposition from "../components/menuComponent.tsx";


const Verifify = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [partnerStatus, setPartnerStatus] = useState(-1);
  const [vendorDetails, setVendorDetails] = useState(null);
  const [driverDetails, setDriverDetails] = useState(null);
  const { setQueryEmail } = useAuth();
  const [user, setUser] = useState();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    if (email) setQueryEmail(email);
    const unsubscribe = getAuth().onAuthStateChanged(user => {
      if (!user) {
        navigate("/auth")
      } else {
        setUser(user);
        const at = sessionStorage.getItem('at')
        if (!at) {
          // const auth = getAuth();
          // signOut(auth).then(() => {
          //   navigate("/auth")
          // }).catch((error) => {
          // });
        }
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchpartnerStatus = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const email = queryParams.get("email");
        if (email) setQueryEmail(email);
        const response = await fetch(
          `https://api.picapool.com/v1/register/get-partner-status`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ email: email }),
          }
        );
        const data = await response.json();
        console.log(data);

        if (data.status === 1) {
          setPartnerStatus(1);
          if (data.partner === "Vendor") setVendorDetails(data.data);
          else setDriverDetails(data.data);
          return;
        }
        if (data.status === 2) {
          setPartnerStatus(2);
          if (data.partner === "Vendor") setVendorDetails(data.data);
          else setDriverDetails(data.data);
          return;
        }
        setPartnerStatus(0);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchpartnerStatus();
  }, []);

  const handleVerifyClick = async () => {
    try {
      const at = sessionStorage.getItem('at')
      if (vendorDetails) {
        const response = await fetch("https://api.picapool.com/v1/vendor", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${at}`
          },
          body: JSON.stringify({
            vendor: {
              email: vendorDetails.email,
              name: vendorDetails.name,
              phone: vendorDetails.phone,
              Stores: {
                create: [
                  {
                    name: vendorDetails.storename,
                    pic: vendorDetails.storepic,
                    latitide: vendorDetails.storelat,
                    longitude: vendorDetails.storelong,
                    catergories: vendorDetails.storeCategories
                  },
                ],
              },
            },
          }),
        });

        if (response.ok) {
          setPartnerStatus(2);
          console.log("Verification successful");
        } else {
          const auth = getAuth();
          signOut(auth).then(() => {
            navigate("/auth")
          }).catch((error) => {
          });
          console.log("Verification failed:", response.statusText);
        }
      }
      if (driverDetails) {
        const response = await fetch("https://api.picapool.com/v1/driver", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${at}`
          },
          body: JSON.stringify({
            driver: {
              email: driverDetails.email,
              name: driverDetails.name,
              phone: driverDetails.phone,
              Cars: {
                create: [
                  {
                    name: driverDetails.carname,
                    pic: driverDetails.carpic,
                    seats: driverDetails.carseats,
                    plate: driverDetails.carplate,
                  },
                ],
              },
            },
          }),
        });

        if (response.ok) {
          setPartnerStatus(2);
          console.log("Verification successful");
        } else {
          const auth = getAuth();
          signOut(auth).then(() => {
            navigate("/auth")
          }).catch((error) => {
          });
        }
      } else {
        console.log("driver details are null");
      }
    } catch (error) {
      console.error("Error verifying driver:", error);
    }
  };
  const tagClass =
    "font-medium text-black/70 bg-orange-200 rounded-md px-3 py-2 w-[12rem]";

  const defaultPic = "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Windows_10_Default_Profile_Picture.svg/512px-Windows_10_Default_Profile_Picture.svg.png?20221210150350"
  var imgUrl = defaultPic;
  var userName = "admin"
  if (user) {
    const temp = user.displayName
    if (temp != null) {
      userName = temp.split(" ")[0]
    }
  }

  return (
    <div>
      <div className="profile">
        <MenuListComposition imgUrl={imgUrl} userName={userName} />

      </div>
      <div className="bg-orange-500 h-screen w-screen flex flex-col items-center justify-center">
        <div className="h-[90vh] w-[90vw] lg:w-[50vw] mx-auto bg-white/90 rounded-md p-10 flex flex-col gap-5">
          {partnerStatus > 0 && (
            <div>
              <div>
                <h2 className="font-medium text-black/70 text-2xl rounded-md py-2 bg-orange-300 mb-2 text-center">
                  {vendorDetails ? "Vendor" : "Driver"} Details
                </h2>
                <ul className="flex flex-col gap-5">
                  <li className="flex flex-row items-center gap-10 shadow-sm rounded-lg text-[1.1rem] p-1">
                    <span className={tagClass}>Name:</span>{" "}
                    {vendorDetails ? vendorDetails.name : driverDetails.name}
                  </li>
                  <li className="flex flex-row items-center gap-10 shadow-sm rounded-lg text-[1.1rem] p-1">
                    <span className={tagClass}>Email:</span>{" "}
                    {vendorDetails ? vendorDetails.email : driverDetails.email}
                  </li>
                  <li className="flex flex-row items-center gap-10 shadow-sm rounded-lg text-[1.1rem] p-1">
                    <span className={tagClass}>Phone Number:</span>{" "}
                    {vendorDetails ? vendorDetails.phone : driverDetails.phone}
                  </li>
                </ul>
              </div>
              {vendorDetails ? (
                <div>
                  <h2 className="font-medium text-black/70 text-2xl rounded-md py-2 bg-orange-300 mb-2 text-center">
                    Store Details
                  </h2>
                  <div className="flex gap-5">
                    <img className="w-60" src={vendorDetails.storepic}></img>
                    <ul className="flex flex-col gap-5">
                      <li className="flex flex-row items-center gap-10 shadow-sm rounded-lg text-[1.1rem] p-1">
                        <span className={tagClass}>Name:</span>{" "}
                        {vendorDetails.storename}
                      </li>
                      <li className="flex flex-row items-center gap-10 shadow-sm rounded-lg text-[1.1rem] p-1">
                        <span className={tagClass}>Store Location:</span>{" "}
                        {vendorDetails.storelocationDesc}
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div>
                  <h2 className="font-medium text-black/70 text-2xl rounded-md py-2 bg-orange-300 mb-2 text-center">
                    Car Details
                  </h2>
                  <div className="flex flex-col md:flex-row gap-5">
                    <img className="w-60" src={driverDetails.carpic}></img>
                    <ul className="flex flex-col gap-5">
                      <li className="flex flex-row items-center gap-10 shadow-sm rounded-lg text-[1.1rem] p-1">
                        <span className={tagClass}>Name:</span>{" "}
                        {driverDetails.carname}
                      </li>
                      <li className="flex flex-row items-center gap-10 shadow-sm rounded-lg text-[1.1rem] p-1">
                        <span className={tagClass}>Plate:</span>{" "}
                        {driverDetails.carplate}
                      </li>
                      <li className="flex flex-row items-center gap-10 shadow-sm rounded-lg text-[1.1rem] p-1">
                        <span className={tagClass}>Number of seats:</span>{" "}
                        {driverDetails.carseats}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
            </div>
          )}
          {partnerStatus === 2 && (
            <div className="bg-green-500 text-white text-center w-full px-5 shadow-lg py-3 font-medium text-xl rounded-md  ">
              {vendorDetails ? "Vendor" : "Driver"} added to Database
            </div>
          )}
          {partnerStatus === 0 && (
            <div className="bg-red-500 text-white text-center w-full px-5 shadow-lg py-3 font-medium text-xl rounded-md  ">
              {vendorDetails ? "Vendor" : "Driver"} details not found, please
              register yourself through the picapool partners app
            </div>
          )}
          {partnerStatus === 1 && (
            <div className={`mx-auto flex flex-row gap-5 mt-5 w-full`}>
              <button
                onClick={handleVerifyClick}
                className="bg-green-500 text-white w-full px-5 shadow-lg py-3 font-medium text-xl rounded-md hover:-translate-y-1 transition-all"
              >
                Verify
              </button>
              <button className="bg-red-500 text-white w-full px-5 shadow-lg py-3 font-medium text-xl rounded-md hover:-translate-y-1 transition-all">
                Reject
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Verifify;
