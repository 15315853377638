import React, { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { useNavigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDI8x-BZfGJZBRbM73-FoRAmapnPw-qlmg",
  authDomain: "picapool-2ead6.firebaseapp.com",
  projectId: "picapool-2ead6",
  storageBucket: "picapool-2ead6.appspot.com",
  messagingSenderId: "760251999490",
  appId: "1:760251999490:web:e5ae97d0239e755b1bce1b",
  measurementId: "G-5MQ7FPK557"
};

initializeApp(firebaseConfig);

const Home = () => {

  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(user => {
      if (user) {
      } else {
        navigate("/auth")
      }
      console.log(user)

    });
    return () => unsubscribe();
  }, [navigate]);

  return <div>Home</div>;
};

export default Home;
