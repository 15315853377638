import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Verifify from "./pages/Verify";
import Partner from "./pages/Delete/Partner";
import AppUser from "./pages/Delete/AppUser";
import LoginPage from "./pages/Auth.tsx";
import { AuthProvider } from "./AuthProvider.tsx";
function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/auth" element={<LoginPage />} />
          <Route path="/verify" element={<Verifify />} />
          <Route path="/delete-user" element={<AppUser />} />
          <Route path="/delete-partner" element={<Partner />} />
        </Routes>
      </Router>
    </AuthProvider>

  );
}

export default App;
