import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

type AuthContextType = {
  queryEmail: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  setQueryEmail: (email: string | null) => void;
  setAccessToken: (token: string | null) => void;
  setRefreshToken: (token: string | null) => void;
};

const AuthContext = createContext<AuthContextType>({
  queryEmail: null,
  accessToken: null,
  refreshToken: null,
  setQueryEmail: () => {},
  setAccessToken: () => {},
  setRefreshToken: () => {}
});

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [queryEmail, setQueryEmail] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [refreshToken, setRefreshToken] = useState<string | null>(null);

  useEffect(() => {
    const storedAccessToken = sessionStorage.getItem('at');
    const storedRefreshToken = sessionStorage.getItem('rt');
    if (storedAccessToken) setAccessToken(storedAccessToken);
    if (storedRefreshToken) setRefreshToken(storedRefreshToken);
  }, []);

  return (
    <AuthContext.Provider value={{
      queryEmail,
      setQueryEmail,
      accessToken,
      setAccessToken,
      refreshToken,
      setRefreshToken
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
