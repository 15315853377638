import React, { useEffect } from 'react';
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import '../index.css'
import { useAuth } from '../AuthProvider.tsx';


function LoginPage() {
  const navigate = useNavigate();
  const { queryEmail } = useAuth();
  const { setAccessToken, setRefreshToken } = useAuth();

  useEffect(() => {
    const unsubscribe = getAuth().onAuthStateChanged(user => {
      if (user) {
        navigate("/verify")
      }
    });
    return () => unsubscribe();
  }, [navigate]);


  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    auth.languageCode = 'it';
    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.idToken;
  
      const resp = await loginOnServer(token ?? "null");
      if (!resp) return;  
  
      if (resp.status === 201) {
        const data = await resp.json();

        setAccessToken(data.access_token)
        setRefreshToken(data.refresh_token)
        sessionStorage.setItem('at', data.access_token);
        sessionStorage.setItem('rt', data.refresh_token);

        if (queryEmail) {
          const redirectUrl = `/verify?email=${queryEmail}`;
          window.location.href = redirectUrl; 
        } else {
          navigate("/"); 
        }
      } else {
        console.error("Failed to log in:", await resp.text());
      }
    } catch (error) {
      console.error("Error during sign in process:", error);
    }
  };
  

  return (<div className="loginPage">
    <button onClick={handleSignIn} className="rounded-md border-10 border-blue bg-white/[.75] google-btn hover:bg-white">
      <svg className="h-7 google-icon w-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="240px" height="240px"><path fill="#fbc02d" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12	s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" /><path fill="#e53935" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039	l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" /><path fill="#4caf50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" /><path fill="#1565c0" d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" /></svg>
      Continue with Google
    </button>
  </div>);
}

async function loginOnServer(token: string) {
  const response = await fetch(
    `https://api.picapool.com/v1/auth/verify`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ "token": token, "role": "admin" }),
    }
  );

  if (response.status === 201) {
    // setAccessToken = response.accc
  }

  return response
}

export default LoginPage;