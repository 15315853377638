import React, { useState } from "react";

const Partner = () => {
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Email:", email);
    console.log("Reason:", reason);
    setEmail("");
    setReason("");
  };

  return (
    <div className="bg-orange-500 h-screen w-screen flex flex-col items-center justify-center">
      <div className="h-[90vh] w-[90vw] lg:w-[50vw] mx-auto bg-white/90 rounded-md p-10 flex flex-col gap-5">
        <h2 className="font-medium text-black/70 text-2xl rounded-md py-2 bg-orange-300 mb-2 text-center">
          Delete Partner
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleEmailChange}
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="reason"
              className="block text-sm font-medium text-gray-700"
            >
              Reason for leaving
            </label>
            <textarea
              id="reason"
              name="reason"
              value={reason}
              onChange={handleReasonChange}
              className="mt-1 p-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500"
              rows="4"
              required
            ></textarea>
          </div>
          <button
            type="submit"
            className="bg-orange-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500"
          >
            Delete Account
          </button>
        </form>
      </div>
    </div>
  );
};

export default Partner;
